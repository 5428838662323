import React from 'react';

interface MissingUploadBtnProps {
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>, itemKey?: number) => void;
  item?: { key: number; uploaded?: string };
  accept?: string;
  buttonStyle?: React.CSSProperties;
}

const MissingUploadBtn: React.FC<MissingUploadBtnProps> = ({ handleFileUpload, item, accept, buttonStyle }) => {
  const itemKey = item?.key;

  return (
    <div>
            <label htmlFor={`fileInput-${itemKey ?? 'general'}`} style={buttonStyle} className="md:hover:bg-offsetPlus text-textOff dark:text-textOffDark md:hover:text-textMain dark:md:hover:bg-offsetPlusDark dark:md:hover:text-textMainDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans select-none items-center relative group/button justify-center text-center items-center rounded-full cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium h-8 cursor-pointer">
                <div className="flex items-center leading-none justify-center gap-xs bg-cta text-white hover:shadow-md py-2 rounded-full px-4" style={buttonStyle}>
                    <div className="text-align-center relative ml-[4px] text-[14px] text-white font-semibold"  style={buttonStyle}>
                        Upload
                    </div>
                    <input
                        type="file"
                        id={`fileInput-${itemKey ?? 'general'}`}
                        style={{ display: 'none' }}
                        onChange={(event) => handleFileUpload(event, itemKey)}
                        accept={accept}
                    />
                </div>
            </label>
        </div>
  );
};

export default MissingUploadBtn;