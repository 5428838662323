import { ILayoutView } from './layout-view.types';

const LayoutView = (props: ILayoutView) => {
  const { children } = props;

  return (
    <div className="flex flex-col" style={{ height: 'calc(100vh - 100px)' }}>
      <div className="flex-grow overflow-hidden">{children}</div>
    </div>
  );
};

export { LayoutView };
