import { useState } from 'react';
import { IVerifyEmail } from './verify-email.types';
import ReCAPTCHA from '../recapcha/recapcha';
import useRecaptcha from '../../hooks/useRecapcha';

const VerifyEmail = ({
  email,
  loading,
  capchaToken,
  handleResend,
  handleVerify,
  handleChangeEmail,
}: IVerifyEmail) => {
  const [otp, setOtp] = useState('');
  const { capchaToken: token, recaptchaRef, handleRecaptcha } = useRecaptcha();

  return (
    <div
      style={{
        maxWidth: '400px',
        margin: 'auto',
        paddingBottom: '1rem',
        textAlign: 'left',
      }}
    >
      <h2 style={{ color: '#333', marginBottom: '.5rem' }}>
        Verify Your Email
      </h2>
      <p style={{ fontSize: '16px', color: '#555', marginBottom: '1rem' }}>
        Please enter the verification code sent to your email{' '}
        <strong>{email}</strong> to verify your account.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <input
          type="text"
          placeholder="Enter verification code"
          style={{
            borderWidth: '1px',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0',
            width: '100%',
            boxSizing: 'border-box',
          }}
          value={otp}
          onChange={e => {
            const numericValue = e.target.value.replace(/[^0-9]/g, '');
            setOtp(numericValue);
          }}
        />
        <a
          href="#"
          onClick={async e => {
            e.preventDefault();
            const resp = await handleResend();
            if (resp === true) {
              alert('Verification Code Sent Successfully');
            }
          }}
          style={{
            color: 'rgb(19,98,223)',
            textDecoration: 'none',
            margin: '10px 0',
            fontSize: '0.9rem',
          }}
        >
          Resend verification code
        </a>
        {!capchaToken && (
          <div
            style={{
              minHeight: '78px',
              marginTop: '1rem',
            }}
          >
            <ReCAPTCHA ref={recaptchaRef} onChange={handleRecaptcha} />
          </div>
        )}
      </div>
      <button
        disabled={loading || otp.length === 0 || (!token && !capchaToken)}
        style={{
          borderRadius: 4,
          backgroundColor: 'rgb(19,98,223)',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          fontSize: '1.1rem',
          color: '#fff',
          borderWidth: 0,
          opacity: loading ? 0.5 : 1,
          marginTop: '1rem',
        }}
        onClick={async () => {
          const resp = await handleVerify(otp, token);
          if (resp === 'Invalid Capcha') {
            handleRecaptcha('');
            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
            }
          }
        }}
      >
        Submit
      </button>
      <button
        disabled={loading}
        style={{
          borderRadius: 4,
          backgroundColor: 'gray',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          fontSize: '1.1rem',
          color: '#fff',
          borderWidth: 0,
          opacity: loading ? 0.5 : 1,
          marginLeft: '.5rem',
          marginTop: '1rem',
        }}
        onClick={handleChangeEmail}
      >
        Change Email
      </button>
    </div>
  );
};

export default VerifyEmail;
