import { useRef, useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import { xmitCardArtifact } from '../jcl-artifact-extract/jcl-artifact-extract.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const JCLCCExtractPage = () => {
  const codeSnippetRef: any = useRef<any>(null);
  const [copySuccess, setCopySuccess] = useState('');

  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );

  const copyToClipboard = async () => {
    const text = codeSnippetRef.current?.textContent || '';
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4 pb-8">
        <h1 className="text-3xl font-semibold mt-4">
          {currentContent?.heading}
        </h1>
        <h1 className="text-3xl text-grey20 my-4">{currentContent?.hrLine}</h1>

        <div className="text-justify max-w-[75%] mx-auto">
          <h2 className="text-xl text-black font-semibold mb-2">
            {currentContent?.section1Title}
          </h2>
          <p className="pb-2">{currentContent?.section1Description1}</p>
          <p className="pb-2">{currentContent?.section1Description2}</p>
          <h2 className="text-xl text-black font-semibold mb-2 mt-4">
            {currentContent?.section2Title}
          </h2>
          <p className="pb-2">{currentContent?.section2Description1}</p>
          <p className=" pb-2">{currentContent?.section2Description2}</p>
          <h2 className="text-xl text-black font-semibold mb-2 mt-4">
            {currentContent?.section3Title}
          </h2>
          <p className="pb-2">{currentContent?.section3Description1}</p>
          <p className=" pb-6">{currentContent?.section3Description2}</p>
          <div className="relative">
            <div className="absolute right-8 top-4">
              <div>
                <FaCopy
                  className="cursor-pointer text-xl hover:text-blue-500"
                  onClick={copyToClipboard}
                />
                {copySuccess && (
                  <div className="absolute -right-4 text-sm text-green-500 ml-2 text-center">
                    {copySuccess}
                  </div>
                )}
              </div>
            </div>
            <pre className="bg-gray-100 p-4 rounded-lg overflow-auto">
              <code ref={codeSnippetRef} style={{ lineHeight: '1.7rem' }}>
                {xmitCardArtifact()}
              </code>
            </pre>
          </div>
          <p className="mt-4">{currentContent?.section3Description3}</p>
          <p className="mt-4">
            <strong>{currentContent?.note}</strong> {currentContent?.noteText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JCLCCExtractPage;
