import { useRef, useState } from 'react';
import { useCallChainUtils } from '../../../hooks/useCallChainUtils';
import LoaderComponent from '../../../components/loader/loader.component';
import CanvasComponent from '../../../components/canvas/canvas';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const CallChainPage = () => {
  const Viewer = useRef<any>(null);
  const scanCompleted = useSelector(
    (state: any) => state.appGlobal.scanCompleted,
  );
  const [tool, setTool] = useState<any>('pan');
  const [value, setValue] = useState<any>();
  const {
    loading,
    svgString,
    programIds,
    jclArtifactIds,
    selectedProgramId,
    usedColors,
    setSelectedProgramId,
    error,
  } = useCallChainUtils();
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );

  return (
    <div
      className="w-full h-auto"
      style={{ height: 'auto', overflow: 'hidden' }}
    >
      <div className="flex flex-col justify-center mt-2 gap-4">
        <h1 className=" text-center text-xl font-semibold">
          {currentContent?.heading}
        </h1>
      </div>
      <div className="flex flex-col justify-center items-center mt-4 gap-4 text-center">
        {loading && <LoaderComponent title="Loading..." />}
      </div>
      {!scanCompleted && (
        <div className="flex flex-col justify-center items-center mt-4 gap-4 text-center">
          <h3>{currentContent?.scanNotCompletedMsg}</h3>
        </div>
      )}
      {scanCompleted && error && (
        <div className="flex flex-col justify-center items-center mt-4 gap-4 text-center">
          <h3>{currentContent?.errorMsg}</h3>
        </div>
      )}
      {!loading && !!svgString && (
        <div>
          <CanvasComponent
            selectedProgramId={selectedProgramId}
            setSelectedProgramId={setSelectedProgramId}
            svgString={svgString}
            programIds={programIds}
            jclArtifactIds={jclArtifactIds}
            usedColors={usedColors}
          />
        </div>
      )}
    </div>
  );
};

export default CallChainPage;
