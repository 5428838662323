import Chatbot from 'react-chatbot-kit';
import MessageParser from './message-parser';
import ActionProvider from './action-provider';
import { IChatResponse, getChatbotConfig } from './config';
import { appTheme } from '../../styles/theme';
import { useChatBotUtils } from '../../hooks/useChatBotUtils';
import { useEffect, useState } from 'react';
import { fetchBotResponse } from './chatbot.service';
import { SESSION_HEADER } from '../../constants/app.constant';
import setAuthHeaders from '../../interceptors/auth.interceptor';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import axios from 'axios';
import { externalConfig } from '../../utils/misc.utils';

const ChatFooter = () => {
  const chatBotUtils = useChatBotUtils();

  function handleClick() {
    axios.post(`${externalConfig?.scannerUrl || process.env.REACT_APP_API_URL}/file/shutdown`, {}).then((res)=>{
      console.log("Shutdown service initiated")
    }).catch((err)=>console.log(err)).finally(()=>{
      chatBotUtils.restart()
    });    
  }
  return (
    <span
      onClick={handleClick}
      id="insent-message-input-buttons-wrapper"
      className="sc-bGbJRg jhohUS absolute bottom-[16px] rounded-md"
      style={{
        background: appTheme.colors.cta,
        height: '3.4rem',
        color: 'white',
        minWidth: '18rem',
        width: 'calc(25% - 4px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <button id="insent-message-input-bot-icon" className="sc-bEjcJn cRQNxc">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5699 2.43462C12.9111 1.77664 11.524 1.04884 10.9281 0.752266C10.797 0.687049 10.6462 0.783104 10.6462 0.92948V2.61823C10.6462 2.79542 10.74 2.95925 10.8903 3.05313C11.2149 3.25591 11.7819 3.62743 12.0764 3.92272C14.2132 6.06052 14.2132 9.53633 12.0764 11.6731C9.93971 13.8066 6.46605 13.8066 4.3261 11.6731C2.19152 9.53419 2.19152 6.06052 4.3261 3.92272C4.33146 3.91629 4.34862 3.90556 4.35612 3.89592L5.4534 4.98948C5.51651 5.05237 5.62415 5.00756 5.62399 4.91846L5.61731 1.34529C5.6171 1.23509 5.52778 1.14581 5.41757 1.14567L1.84695 1.1409C1.75779 1.14078 1.71306 1.24856 1.7761 1.31161L2.86588 2.40138C2.85837 2.41425 2.84658 2.42711 2.83693 2.43462C-0.125331 5.39581 -0.125331 10.2 2.83693 13.1612C5.80348 16.1256 10.6044 16.1256 13.5699 13.1612C16.53 10.2 16.5332 5.39581 13.5699 2.43462Z"
            fill="#ffffff"
          ></path>
        </svg>
      </button>
      <span className="ml-2">Restart session</span>
    </span>
  );
};

const ChatBot = () => {
  const [initialData, setInitialData] = useState<IChatResponse>({
    message: '',
    options: [],
  });
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const currentIntent = useSelector(
    (state: RootState) => state.appGlobal.currentIntent,
  );
  const sessionId = sessionStorage.getItem(SESSION_HEADER);

  useEffect(() => {
    (async () => {
      if (!sessionId) return;
      setAuthHeaders();
      const firstPromptId = '000';
      try {
        const options = await fetchBotResponse(firstPromptId, '');
        const promptResp = options.find(
          (option: any) => option?.type === 'prompt-response',
        );

        let restOption = options.filter(
          (option: any) => option?.type !== 'prompt-response',
        );

        if (license?.licenseType === 'ONPREM') {
          restOption = restOption.filter(
            (option: any) => !option?.hideForOnPrem,
          );
        }

        setInitialData({ message: promptResp?.message, options: restOption });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [sessionId, currentIntent]);

  return (
    <div className="h-full" id="chat-box-container">
      {initialData.message && (
        <Chatbot
          config={getChatbotConfig(initialData)}
          placeholderText={'Type a message...'}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          validator={msg => !!msg}
        />
      )}
      <div>
        <ChatFooter />
      </div>
    </div>
  );
};

export default ChatBot;
