import { SVGProps } from 'react';
const SVGExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'external_link_fill'}</title>
    <g
      id="\u9875\u9762-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="File" transform="translate(-480.000000, -240.000000)">
        <g
          id="external_link_fill"
          transform="translate(480.000000, 240.000000)"
        >
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
            id="MingCute"
            fillRule="nonzero"
          />
          <path
            d="M21,4 C21,3.44772 20.5523,3 20,3 L15,3 C14.4477,3 14,3.44772 14,4 C14,4.55229 14.4477,5 15,5 L17.5858,5 L15.4766,7.10925 C16.0231,7.49844 16.5016,7.97695 16.8908,8.52347 L19,6.41424 L19,9 C19,9.55228 19.4477,10 20,10 C20.5523,10 21,9.55228 21,9 L21,4 Z M15.4765,7.10925 C14.4957,6.41079 13.2958,6 12,6 L5,6 C3.89543,6 3,6.89543 3,8 L3,19 C3,20.1046 3.89543,21 5,21 L16,21 C17.1046,21 18,20.1046 18,19 L18,12 C18,10.7042 17.5892,9.50428 16.8907,8.52347 L10.7071,14.7071 C10.3166,15.0976 9.68342,15.0976 9.29289,14.7071 C8.90237,14.3166 8.90237,13.6834 9.29289,13.2929 L15.4765,7.10925 Z"
            id="\u5F62\u72B6"
            fill="#09244B"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SVGExternalLink;
