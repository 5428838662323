import React from 'react';

const AttachFilesComponent = ({
  handleFileUpload,
  accept,
  disabled,
  buttonStyle
}: {
  handleFileUpload: any;
  accept: string;
  disabled: boolean;
  buttonStyle?: React.CSSProperties;
}) => {
  return (
    <div>
      <label
        // type="button"
        htmlFor="file-upload"
        className="mt-2 md:hover:bg-offsetPlus text-textOff dark:text-textOffDark md:hover:text-textMain dark:md:hover:bg-offsetPlusDark dark:md:hover:text-textMainDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans select-none items-center relative group/button justify-center text-center items-center rounded-full cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium h-8 cursor-pointer"
      >
        <div className=" rounded-sm bg-black text-white pointer-events-none absolute z-130 opacity-0 shadow-sm transition-all delay-200 duration-200 group-hover/button:translate-y-0 group-hover/button:translate-x-0 group-hover/button:opacity-100 bottom-[120%] translate-y-1">
          <div className="py-xs px-sm rounded flex items-center gap-x-sm bg-textMain dark:bg-offsetPlusDark">
            <div className="  font-sans text-xs font-medium text-textMain dark:text-textMainDark selection:bg-superDuper selection:text-textMain px-2 py-[4px]">
              <span className="text-white">Attach your artifacts</span>
            </div>
          </div>
        </div>
        <div className="flex items-center leading-none justify-center gap-xs bg-cta text-white hover:shadow-md py-2 rounded-full px-4 mt-2" style={buttonStyle}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="circle-plus"
            className="svg-inline--fa fa-circle-plus fa-fw fa-1x"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="black"
            width={16}
            height={16}
          >
            <path
              fill="currentColor"
              d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344c0 13.3 10.7 24 24 24s24-10.7 24-24V280h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V168c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"
            ></path>
          </svg>
          <div className="text-align-center relative ml-[4px] text-[14px] text-white font-semibold">
            Attach
          </div>
        </div>
      </label>
      <input
        id="file-upload"
        type="file"
        multiple
        disabled={disabled}
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
    </div>
  );
};

export default AttachFilesComponent;
