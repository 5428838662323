import { SharedService } from '../services/shared-service';
import { appTheme } from '../styles/theme';

const appColors = appTheme.colors;

const unSupportedModules = ['DBNTRY', 'SQLADR', 'SQLBEX', 'ISPLINK'];
export const supportedIBMUtilities = [
  'SORT',
  'IDCAMS',
  'IEFBR14',
  'IEBGENER',
  'IEBCOPY',
  'DFSORT',
  'SYNCSORT',
];
const dsnRegex = new RegExp('^DSN[0-9A-Z]+$');

export const colorLegendData = [
  { color: '#2CA02C', label: 'Driver Program' },
  { color: '#FF7F0E', label: 'Missing Program' },
  { color: '#1F77B4', label: 'Recursive Program' },
  { color: '#FFD700', label: 'Dynamic Call' },
  { color: '#8C564B', label: 'MQ API' },
  { color: '#9467BD', label: 'BPX Module' },
  { color: '#00FFFF', label: 'Supported System Program' },
  { color: '#FF6347', label: 'Unsupported System Program' },
  { color: '#FF0000', label: 'Unsupported Module' },
  { color: '#A9A9A9', label: 'Sub Program' },
  { color: '#008080', label: 'Supported IBM Utility' },
  { color: 'rgba(19, 99, 223, 1)', label: 'Missing | Unsupported Utility' },
];

export const getCardColors = (
  programName: string,
  driverProgramName: string,
  isRecursive?: boolean,
  isDynamic?: boolean,
  isMissingProgram?: boolean,
  isDriverProgram?: boolean,
  isSupported?: boolean,
  isCalledFromJCL?: boolean,
  nodeType?: string,
) => {
  let backgroundColor = '#A9A9A9';
  let textColor = appColors.black;
  const isFromVariable = programName.toLowerCase().includes('variable');

  if (isSupported === false) {
    backgroundColor = '#FF0000';
    textColor = appColors.white;
  } else if (
    supportedIBMUtilities.includes(programName.toUpperCase()) ||
    dsnRegex.test(programName.toUpperCase())
  ) {
    backgroundColor = '#008080';
    textColor = appColors.white;
  } else if (isDynamic || isFromVariable) {
    backgroundColor = '#FFD700';
  } else if (
    !isMissingProgram &&
    isSupported !== undefined &&
    (driverProgramName === programName || isDriverProgram)
  ) {
    backgroundColor = '#2CA02C';
  } else if (programName.startsWith('CEE') || programName === 'ILBOABN0') {
    textColor = appColors.black;
    backgroundColor = '#00FFFF';
  } else if (unSupportedModules.includes(programName)) {
    backgroundColor = '#FF0000';
    textColor = appColors.white;
  } else if (programName.startsWith('CSQ') || programName.startsWith('MQ')) {
    backgroundColor = '#8C564B';
    textColor = appColors.white;
  } else if (programName.startsWith('BPX')) {
    textColor = '#9467BD';
    backgroundColor = appColors.purpleSmoothLight;
  } else if (
    programName.startsWith('IRX') ||
    programName.startsWith('IKJEFT')
  ) {
    textColor = appColors.white;
    backgroundColor = '#FF6347';
  } else if (isRecursive) {
    backgroundColor = '#1F77B4';
    textColor = appColors.white;
  } else if (isCalledFromJCL) {
    textColor = 'white';
    backgroundColor = 'rgba(19, 99, 223, 1)';
    SharedService.missingUtilities.add(programName);
  } else if (isMissingProgram || isSupported === undefined) {
    backgroundColor = '#FF7F0E';
    if (nodeType !== 'step' && nodeType !== 'job') {
      SharedService.missingPrograms.add(programName);
    }
  }

  return { backgroundColor, textColor };
};

export const addColorLegend = (
  svgString: string,
  usedColorsArray: string[],
  legend: any,
): string => {
  const legendX = 20;
  let legendY = 23;
  const circleRadius = 8;
  const textOffset = 4;

  let legendData = colorLegendData.filter(legend =>
    usedColorsArray.includes(legend.color),
  );

  if (legend) {
    legendData = Object.entries(legend).map(([key, value]: any) => ({
      color: value?.nodeColor,
      label: key,
    }));
  }
  const result = svgString.match(/<polygon fill="#ffffff" [^>]*>/g);

  if (!result?.length) {
    throw new Error('No match found');
  }

  const svgResult = svgString.match(/<svg[^>]*>/g);

  if (!svgResult?.length) {
    throw new Error('No match found');
  }

  const height = svgString.match(/height="([^"]*)"/g);

  if (!height?.length) {
    throw new Error('No match found');
  }

  const heightValue = height[0].match(/\d+/g);

  if (!heightValue?.length) {
    throw new Error('No match found');
  }

  const newHeight = parseInt(heightValue[0]) + 100;

  const newSvgString = height[0].replace(heightValue[0], newHeight.toString());

  let newSVGLine = svgResult[0].replace(height[0], newSvgString);

  const width = svgString.match(/width="([^"]*)"/g);

  if (!width?.length) {
    throw new Error('No match found');
  }

  const widthValue = width[0].match(/\d+/g);

  if (!widthValue?.length) {
    throw new Error('No match found');
  }
  const svgWidth = parseInt(widthValue[0]) + 600;

  const svgWidthString = width[0].replace(widthValue[0], svgWidth.toString());
  const viewBoxMatch = svgString.match(/viewBox="([^"]*)"/);
  if (!viewBoxMatch) throw new Error('No match found for viewBox');
  const viewBoxValues = viewBoxMatch[1].split(' ');
  const newViewBoxWidth = svgWidth;
  const newViewBoxHeight = newHeight;
  const newViewBox = `viewBox="${viewBoxValues[0]} ${viewBoxValues[1]} ${newViewBoxWidth} ${newViewBoxHeight}"`;
  const viewBoxSVGLine = newSVGLine.replace(viewBoxMatch[0], newViewBox);
  const finalSVGLine = viewBoxSVGLine.replace(width[0], svgWidthString);
  // Generate the legend section
  let prevTextX: any = 0;
  const legendData1 = legendData.slice(0, 6);
  const legendData2 = legendData.slice(6, 10);
  const legendSection = legendData1
    .map((data, index) => {
      let textX = index === 0 ? legendX : legendX + prevTextX;
      if (textX >= svgWidth) {
        textX = 0;
        legendY = 30;
      } else {
        prevTextX = textX + data.label.length * 6 + 10;
      }
      return `
      <circle cx="${textX + 5}" cy="${legendY - 3}" r="${circleRadius}" fill="${
        data.color
      }">
      ${data.label === 'Dynamic Call' ? `<title>A method of invoking programs where the call is resolved at runtime rather than at compile time.</title>` : ''}
      </circle>
      <text x="${
        textX + circleRadius + 10
      }" y="${legendY}" font-family="Lato" font-size="12" fill="#151b23">${
        data.label
      }</text>
    `;
    })
    .join('');
  const legendSection2 = legendData2
    .map((data, index) => {
      let textX = index === 0 ? legendX : legendX + prevTextX;
      if (textX >= svgWidth) {
        textX = 0;
        legendY = 30;
      } else {
        prevTextX = textX + data.label.length * 6 + 10;
      }
      return `
      <circle cx="${textX + 5}" cy="${legendY - 3}" r="${circleRadius}" fill="${
        data.color
      }">
      ${data.label === 'Dynamic Call' ? `<title>A method of invoking programs where the call is resolved at runtime rather than at compile time.</title>` : ''}
      </circle>
      <text x="${
        textX + circleRadius + 10
      }" y="${legendY}" font-family="Lato" font-size="12" fill="#151b23">${
        data.label
      }</text>
    `;
    })
    .join('');
  let rightLegend =
    `<g id="color-coding-legend" transform="translate(-10 20)">` +
    legendSection +
    `</g>`;
  rightLegend +=
    `<g id="color-coding-legend" transform="translate(-10 50)">` +
    legendSection2 +
    `</g>`;
  let modifiedSvgString = svgString.replace(
    result[0],
    `${result[0]} ${rightLegend}`,
  );

  modifiedSvgString = modifiedSvgString.replace(svgResult[0], finalSVGLine);
  return modifiedSvgString;
};
