const TransformationPage = () => {
  return (
    <div>
      <div className="bg-white rounded-lg shadow-md p-6 mx-auto">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold">Transformation Summary</h2>
          <div className="flex gap-2">
            <button
              style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-primary text-white hover:bg-primary/90"
            >
              Download Error Logs
            </button>
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-secondary text-secondary-foreground hover:bg-secondary/90"
              style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
            >
              Create Support Ticket
            </button>
            <button
              style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-green-500 text-white hover:bg-green-600"
            >
              Transformation Summary Report
            </button>
          </div>
        </div>
        <div className="h-[1px] bg-greyCardOutline my-2 mb-4" />
        <div className="grid grid-cols-3 gap-4">
          <div
            className="border text-center bg-tealDarkBase text-primary-foreground rounded-lg shadow-md"
            data-v0-t="card"
          >
            <div className="p-6 text-white">
              <p className="text-lg font-medium mb-2 text-white">
                Transformed Successfully
              </p>
              <div className="text-4xl font-bold text-white">50</div>
            </div>
          </div>
          <div
            className="border text-center bg-secondary text-secondary-foreground rounded-lg shadow-md"
            data-v0-t="card"
          >
            <div className="p-6 bg-greyLight">
              <p className="text-lg font-medium mb-2">
                Transformation In Progress
              </p>
              <div className="text-4xl font-bold">20</div>
            </div>
          </div>
          <div
            className="border text-center bg-redBase text-white rounded-lg shadow-md"
            data-v0-t="card"
          >
            <div className="p-6">
              <p className="text-lg font-medium mb-2 text-white">
                Transformation Failed
              </p>
              <div className="text-4xl font-bold text-white">02</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6 mx-auto min-h-[20rem] w-full mt-4">
        <h2 className="text-2xl font-bold">Transform Queue</h2>
      </div>
    </div>
  );
};

export default TransformationPage;
