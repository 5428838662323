import { useEffect, useRef, useState } from 'react';
import { SharedService } from '../../../services/shared-service';
import { EAppModal } from '../../../redux/app-global/app-global.types';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal } from '../../../redux/app-global';
import { RootState } from '../../../redux/store';
import SVGRightChevron from '../../../assets/images/svg/right-chevron.icon';

interface IOptions {
  title: string;
  options: Array<{ id: string; name: string; handler: () => void }>;
}

const mainJourneyOptionIds = ['002', '02d', '2d1'];

const Options = (props: any) => {
  const { options, title, handler, initialResp } = (props as any)
    .payload as any;
  const func = useRef<any>(SharedService.handleOption);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const resetChatBot = useSelector(
    (state: RootState) => state.appGlobal.resetChatbot,
  );
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (resetChatBot) {
      setOptionsVisible(true);
    }
  }, [resetChatBot]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return <div className="w-full h-[4rem]" />;

  if (!optionsVisible) {
    return null;
  }

  const handleMouseEnter = () => {
    setTimeout(() => {
      setIsHovered(true);
    }, 400);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  return (
    <div className="options w-fit" ref={optionsRef}>
      <div className="options-container">
        {options.map((option: any) => {
          const isMainOption = mainJourneyOptionIds.includes(option.id);
          return (
            <div
              className={`option-item flex flex-row justify-between items-center shadow-sm ml-auto ${isMainOption ? 'main-option' : ''}`}
              onMouseEnter={isMainOption ? handleMouseEnter : undefined}
              onMouseLeave={isMainOption ? handleMouseLeave : undefined}
              onClick={() => {
                if (option.id === '2d2') {
                  dispatch(setActiveModal(EAppModal.ASSISTED_SERVICE));
                  return;
                } else if (option.intent === 'CONTACT_US') {
                  dispatch(setActiveModal(EAppModal.LEAD_INFORMATION));
                  return;
                } else if (handler) {
                  handler(option);
                } else if (initialResp && func.current) {
                  func.current(option);
                }
                setOptionsVisible(false);
              }}
              key={option.id}
            >
              {option.message}
              {isMainOption && (
                <SVGRightChevron stroke={isHovered ? '#fff' : '#1f91e7'} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Options;
