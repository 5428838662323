import { useState } from 'react';
import SVGChevron from '../../../assets/images/svg/chevron.icon';
import { appTheme } from '../../../styles/theme';
import { UploadFile } from '../upload-list.types';

interface IFileSummarySection {
  zipFileStatus: Record<string, any>;
  title: string;
  parentFile: UploadFile;
  status: string;
  color: string;
  statusColor: string;
  category: string;
}
const FileSummarySection = ({
  zipFileStatus,
  title,
  parentFile,
  status,
  color,
  category,
  statusColor,
}: IFileSummarySection) => {
  const [open, setOpen] = useState(false);
  const fileCount =
    zipFileStatus[parentFile.name].child?.[category]?.length || 0;
  return (
    <div className="mt-2 border">
      <div className="border-b pb-2 p-4">
        <div
          className="flex justify-between items-center"
          onClick={() => setOpen(!open)}
        >
          <div className="flex flex-row items-center">
            <h2 className="mx-2 -mt-2 text-sm font-semibold">{title}</h2>
            <div
              style={{ background: color }}
              className="mb-1 text-gray-800 text-xs font-semibold ml-2 px-2.5 py-0.5 rounded"
            >
              {fileCount} {fileCount === 1 ? 'file' : 'files'}
            </div>
          </div>
          <div className="mr-4">
            <SVGChevron style={{ transform: open ? 'rotate(180deg)' : '' }} />
          </div>
        </div>
      </div>
      {open && (
        <div className="mx-4">
          {zipFileStatus[parentFile.name].child?.[category]?.map(
            (content, index) => (
              <div
                key={index}
                style={{
                  borderBottomWidth:
                    index === zipFileStatus[parentFile.name].length - 1 ? 0 : 1,
                  borderBottomColor: appTheme.colors.ddlGrey20,
                  paddingBottom: '12px',
                  paddingTop: '12px',
                }}
                className="flex justify-between items-center p-2 hover:bg-gray-100 rounded-lg transition duration-150 ease-in-out"
              >
                <div className="flex items-center gap-2">
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                    {content.artifactType?.[0]?.toUpperCase() ||
                      content.type?.toUpperCase()}
                  </span>
                  <span className="text-sm">{content.name}</span>
                </div>
                <span
                  className={`text-sm px-4 py-1 rounded-full`}
                  style={{ color: statusColor, background: color }}
                >
                  {status}
                </span>
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default FileSummarySection;
