import { useDispatch } from 'react-redux';
import { setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';

const ArtifactInfo = () => {
  const dispatch = useDispatch();
  const basePath = process.env.REACT_APP_BASE_PATH;
  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4">
        <div
          onClick={() => dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD))}
        >
          <p className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105">
            &larr; Back to Artifact Upload
          </p>
        </div>
        <h1 className="text-3xl font-semibold">
          Trade Settlement Application Artifacts
        </h1>
        <p className="max-w-[70%]">
          The Trade Settlement Application is a sample application designed to
          demonstrate our scanning capabilities. This is a COBOL-DB2-QSAM Batch
          Application usecase that does Securities Trade Matching & Settlement
        </p>
        <p>
          <a
            href={`${basePath}docs/Trade_Settlement_Application_Artifacts.zip`}
            target="_blank"
            download
            className="text-cta"
            rel="noreferrer"
          >
            Download Trade Settlement Application Artifacts
          </a>
        </p>
        <p className="max-w-[70%] mt-4">
          For more detailed information about the Trade Settlement Application
          Artifact, Please download the information PDF below:
        </p>
        <p>
          <a
            href={`${basePath}docs/Batch_Securities_Trade_Settlement_Application.pdf`}
            download
            className="text-cta"
            target="_blank"
            rel="noreferrer"
          >
            Download Trade Settlement Application Information PDF
          </a>
        </p>
      </div>
    </div>
  );
};

export default ArtifactInfo;
