import * as Sentry from '@sentry/react';

export const initSentry = (apiBaseUrl: string, socketApiUrl: string) => {
  if (process.env.NODE_ENV !== 'development') {
    const dsn =
      process.env.REACT_APP_CF_ENV === 'production'
        ? 'https://7fb4be0dbe8c982aa1c05180c6914349@o4507338345218048.ingest.de.sentry.io/4507338356949072'
        : 'https://b71c8b0c88d6d25cf52c84b01d06f7fc@o4507526960381952.ingest.de.sentry.io/4507526962741328';
    Sentry.init({
      dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        new RegExp(`^${apiBaseUrl.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`),
        new RegExp(
          `^${socketApiUrl.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`,
        ),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};
