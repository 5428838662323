import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const CloudframeServicesPage = () => {
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );

  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4">
        <h1 className="text-3xl font-semibold">{currentContent?.heading}</h1>
        <p className="max-w-[70%]">{currentContent?.description1}</p>
        <p className="max-w-[70%]">{currentContent?.description2}</p>
        <p className="max-w-[70%]">{currentContent?.description3}</p>
        <p className="max-w-[70%]">{currentContent?.description4}</p>
      </div>
    </div>
  );
};

export default CloudframeServicesPage;
