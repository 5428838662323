import {
  parsePhoneNumberFromString as parsePhoneNumber
} from 'libphonenumber-js';
import { ApiConfig } from '../model/license.model';

export const externalConfig: any = {};
export const apiConfig: ApiConfig = {};

export function scrollInformationCenterToEnd() {
  const infoCenter = document.getElementById('information-center');
  if (infoCenter) {
    infoCenter.scrollTop = infoCenter.scrollHeight;
  }
}

export function scrollInformationCenterToTop() {
  const infoCenter = document.getElementById('information-center');
  if (infoCenter) {
    infoCenter.scrollTop = 0;
  }
}

export const isValidInternationalPhoneNumber = (number: string) => {
  // Phones for test
  // +84967711901 (VN)
  // +441481244783 (GB)
  // +12133734253 (US)
  // +4915754449596 (DE)

  // Uncomment to choose best choice
  // 1.
  const phoneNumber = parsePhoneNumber(number);
  return phoneNumber && phoneNumber.isValid();
  // 2.
  // isPossiblePhoneNumber() only validates phone number length,
  // return isPossiblePhoneNumber(number, country);
  // 3.
  // while isValidPhoneNumber() validates both phone number length and the actual phone number digits.
  // return isValidPhoneNumber(number, country);
};

export function formatOptionId(id: string): string {
  const [numericPart, alphabeticPart] = id
    .match(/(\d+)([a-zA-Z]*)/)
    ?.slice(1) || ['', ''];
  return parseInt(numericPart, 10) + alphabeticPart.toUpperCase();
}

export function processImageSource(source: string): string {
  if (source) {
    if (process.env.REACT_APP_CF_ENV === 'onprem') {
      const idxAsset = source.indexOf('assets');
      if (idxAsset > -1) {
        return source.substring(idxAsset);
      }
    }
    return source;
  }
  return '';
}
