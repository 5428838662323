import * as React from 'react';
import { SVGProps } from 'react';
const SVGLoader = ({
  width = '80px',
  height = '80px',
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
    }}
    width={width}
    height={height}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M16 50A34 34 0 0 0 84 50A34 39.8 0 0 1 16 50"
      fill="#93dbe9"
      stroke="none"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 52.9;360 50 52.9"
      />
    </path>
  </svg>
);
export default SVGLoader;
