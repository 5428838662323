import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal } from '../../../redux/app-global';
import SVGSuccess from '../../../assets/images/svg/success.icon';
import { RootState } from '../../../redux/store';
import { isCorporateEmailDomain } from '../../../utils/form-validator.utils';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

const ScheduleCalender = () => {
  const [name, setName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );

  useEffect(() => {
    setTimeout(() => {
      const maxHeight = window.innerHeight * 0.95 - 75;
      const elements = document.querySelectorAll('.calendly-inline-widget');

      // Apply the calculated max-height to each element
      elements.forEach((element: any) => {
        element.style.maxHeight = `${maxHeight}px`;
      });
    }, 1000);
  }, []);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: e => {
      setSubmitted(true);
      console.log(e.data.payload);
    },
  });

  useEffect(() => {
    if (userDetails) {
      setName(userDetails.name);
      setCompanyEmail(userDetails.companyEmail);
    }
  }, [userDetails]);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setActiveModal(null));
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: window.innerHeight * 0.95,
          overflow: 'hidden',
        }}
        className="fixed overflow-auto max-w-[40rem] flex w-full flex-col border rounded-lg bg-white p-8 py-4"
      >
        {submitted ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem',
              paddingTop: '1.5rem',
            }}
          >
            <div>
              <SVGSuccess />
            </div>
            <div style={{ marginTop: '2rem' }}>
              <p className="text-xl font-semibold">
                Successfully scheduled the meeting{' '}
              </p>
            </div>
            <div style={{ marginTop: '.5rem' }}>
              <p style={{ fontSize: '1rem' }}>
                Thanks for providing scheduling the meeting. Our support team
                will reach out to you on scheduled time.
              </p>
            </div>
            <div>
              <div className="flex fex-row gap-4 mt-8">
                <button
                  className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="title-font mb-1 text-lg font-medium text-gray-900">
              Schedule a Meeting
            </h2>
            <button
              className="border-0 text-black float-right text-5xl leading-none font-medium outline-none focus:outline-none transition-all hover:scale-110 absolute top-2 right-6 cursor-pointer"
              onClick={handleClose}
            >
              <span className=" text-black h-6 w-6 text-4xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
            <hr className="my-2" />
            <div className="pb-12">
              <InlineWidget
                pageSettings={
                  {
                    // hideEventTypeDetails: true,
                    // hideLandingPageDetails: true,
                  }
                }
                prefill={{
                  name,
                  email: companyEmail,
                }}
                url="https://calendly.com/cloudframe-introduction-mac-boyle/cloudframe-code-navigator-introduction"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleCalender;
