const SVGRightChevron = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 7L14.5 12L9.5 17"
      stroke={props.stroke || '#1f91e7'}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGRightChevron;
