import { IActionCard } from './action-card.types';

const ActionCard = (props: IActionCard) => {
  const { title, onClick } = props;
  return (
    <div
      onClick={onClick}
      className="container mx-auto shadow-sm cursor-pointer"
    >
      <div className="max-w-md mx-auto">
        <div
          className="card rounded-md flex items-center justify-center  min-h-[10rem]
         h-full p-4 px-3 transition-transform duration-500 ease-in-out hover:transform hover:translate-z-1000 hover:-translate-y-1 bg-gradient-to-br 
          from-sky-100 to-blue-100"
        >
          <div className="card-body text-center flex items-center justify-center">
            <p className=" font-semibold">{title}</p>
            <span className="card-text text-white block mb-4"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCard;
