import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const CodeConversionProcess = () => {
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );
  return (
    <div className="h-full w-full flex flex-col items-center ">
      <div className="text-justify flex flex-col justify-center items-center mt-4 gap-4 max-w-[70%]">
        <h1 className="text-3xl font-semibold mb-4 mt-4">
          {currentContent?.heading}
        </h1>

        <p className="mb-4">{currentContent?.description1}</p>
        <ul className=" list-decimal list-inside mb-0">
          <li className="mb-4">
            <strong>{currentContent?.point1Title}</strong> {currentContent?.point1Description}
          </li>
          <li className="mb-4">
            <strong>{currentContent?.point2Title}</strong> {currentContent?.point2Description}
            <ul className="list-disc list-inside ml-6 my-2">
              <li>
                {currentContent?.point2aTitle} {currentContent?.point2aDescription}
              </li>
              <li>
                {currentContent?.point2bTitle} {currentContent?.point2bDescription}
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>{currentContent?.point3Title}</strong> {currentContent?.point3Description}
            <ul className="list-disc list-inside ml-6 my-2">
              <li>
                {currentContent?.point3aTitle} {currentContent?.point3aDescription}
              </li>
              <li>
                {currentContent?.point3bTitle} {currentContent?.point3bDescription}
              </li>
            </ul>
          </li>
        </ul>
        <p className="pb-12">{currentContent?.summary}</p>
      </div>
    </div>
  );
};

export default CodeConversionProcess;
