import { useDispatch, useSelector } from 'react-redux';
import ActionCard from '../../../components/action-card/action-card';
import { setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';
import { RootState } from '../../../redux/store';

const ExtractArtifactPage = () => {
  const dispatch = useDispatch();
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );
  const basePath = process.env.REACT_APP_BASE_PATH;

  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4">
        <div className="text-justify flex flex-col justify-center items-start gap-4 max-w-[75%] pb-4">
          <p className="mt-2">{currentContent?.caption}</p>
          <h1 className="text-2xl font-semibold mx-auto mt-2">
            {currentContent?.heading2}
          </h1>
          <p>{currentContent?.description2}</p>
          <p>{currentContent?.description3}</p>
          <div className="flex flex-row gap-12">
            <a
              href={`${basePath}docs/Trade_Settlement_Application_XMI_Bundled_Artifacts.zip`}
              target="_blank"
              download
              className="text-cta"
              rel="noreferrer"
            >
              {currentContent?.xmiArtifactLinkTitle}
            </a>
            <a
              href={`${basePath}docs/Trade_Settlement_Application_ZIP_Bundled_Artifacts.zip`}
              target="_blank"
              download
              className="text-cta"
              rel="noreferrer"
            >
              {currentContent?.zipArtifactLinkTitle}
            </a>
          </div>
          <p>{currentContent?.description4}</p>
          <p>
            <a
              href={`${basePath}docs/Securities_Trade_Settlement_Application.pdf`}
              download
              className="text-cta"
              target="_blank"
              rel="noreferrer"
            >
              {currentContent?.downloadTradeSettlementLinkText}
            </a>
          </p>
        </div>
        <h1 className="text-3xl text-grey20 mr-auto ml-[12.5%]">
          --------------------------------------------------
        </h1>
        <h1 className="text-2xl font-semibold">{currentContent?.heading}</h1>
        <p className="max-w-[75%] text-justify">
          {currentContent?.description1}
        </p>
        <p className="max-w-[70%] mt-4 ">{currentContent?.description}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2 mb-8">
          <ActionCard
            title={currentContent?.jclCardTitle}
            onClick={() => {
              dispatch(
                setCurrentIntent(EAppIntent.JCL_ARTIFACT_INSTRUMENTATION),
              );
            }}
          />
          <ActionCard
            title={currentContent?.procCardTitle}
            onClick={() => {
              dispatch(
                setCurrentIntent(EAppIntent.JCL_PROC_ARTIFACT_INSTRUMENTATION),
              );
            }}
          />
          <ActionCard
            title={currentContent?.ccCardTitle}
            onClick={() => {
              dispatch(
                setCurrentIntent(EAppIntent.JCL_CC_ARTIFACT_INSTRUMENTATION),
              );
            }}
          />
          <ActionCard
            title={currentContent?.listingsCardTitle}
            onClick={() => {
              dispatch(
                setCurrentIntent(EAppIntent.LISTING_ARTIFACT_INSTRUMENTATION),
              );
            }}
          />
        </div>
        <p className="mb-4">
          <a
            href={`${basePath}docs/Instructions_to_Extract_Mainframe_Artifacts.pdf`}
            download
            className="text-cta text-justify"
            target="_blank"
            rel="noreferrer"
          >
            Download the Complete Artifact Extraction Guide (PDF)
          </a>
        </p>
        <button
          className="mt-0 btn bg-cta hover:shadow-md text-white font-semibold py-2 px-4 rounded-full mb-7"
          onClick={() => {
            dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
          }}
        >
          Proceed to Artifact Upload &rarr;
        </button>
      </div>
    </div>
  );
};

export default ExtractArtifactPage;
