import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import SVGAlert from '../../../assets/images/svg/alert.icon';
import { setActiveModal, setCurrentIntent } from '../../../redux/app-global';
import { EAppIntent } from '../../../redux/app-global/app-global.types';

const MissingArtifactsModal = () => {
  const dispatch = useDispatch();
  const scannerErrorMessage = useSelector(
    (state: RootState) => state.appGlobal.scannerErrorMessage,
  );

  function onClickProceedToReport() {
    dispatch(setActiveModal(null));
    dispatch(setCurrentIntent(EAppIntent.REPORT));
  }

  function onClickArtifactWorkspace() {
    dispatch(setActiveModal(null));
    dispatch(setCurrentIntent(EAppIntent.REPORT));
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50">
      <div className="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-auto max-w-2xl max-h-[44rem] border rounded-lg bg-white p-8">
        <div className="flex flex-col justify-start items-center p-4 pt-0">
          <div>
            <p className="text-xl font-semibold">Missing Artifacts</p>
          </div>
          <div className="h-[1px] mt-4 mb-2 w-full bg-ddlGrey40" />
          <div style={{ marginTop: '.5rem' }}>
            <p>
              During the scan, we identified that certain artifacts are missing.
              Please visit the Artifact Workspace for more detailed information.
            </p>
            {scannerErrorMessage && (
              <p>Additional details: {scannerErrorMessage}</p>
            )}
          </div>
          <div
            className="p-4 shadow-sm border rounded-md mt-4 bg-redSmooth"
            role="alert"
          >
            <div className="flex flex-row gap-4 items-center">
              <div>
                <SVGAlert width={28} height={28} />
              </div>
              <p className="text-lg font-semibold">Warning</p>
            </div>
            <div className="h-[1px] mt-2 mb-2 w-full bg-white" />

            <p className="mt-2">
              We cannot generate the Java code due to missing artifacts. Please
              upload the necessary files to proceed with generating the Java
              code
            </p>
          </div>
          <div>
            <div className="flex fex-row gap-4 mt-8 items-center">
              <button
                className="rounded shadow-sm border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                onClick={onClickProceedToReport}
              >
                Proceed to Report
              </button>
              <button
                className="border shadow-sm border-gray-300 py-2 px-6 min-w-[8rem] rounded"
                onClick={onClickArtifactWorkspace}
              >
                Artifact WorkSpace
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissingArtifactsModal;
