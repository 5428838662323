import axios from 'axios';
import localforage from 'localforage';
import { useEffect, useRef } from 'react';
import 'react-chatbot-kit/build/main.css'; // this has to be placed before the App.css
import { useDispatch, useSelector } from 'react-redux';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import AppModals from './components/app-modals/app-modals';
import { LayoutView } from './components/layout-view/layout-view';
import { SESSION_HEADER, publicEmailDomains } from './constants/app.constant';
import setAuthHeaders from './interceptors/auth.interceptor';
import { Home } from './pages/home/home';
import {
  setActiveModal,
  setCurrentIntent,
  setScanCompleted,
  setScanProgress,
  setScannerErrorMessage,
  setUploadedArtifacts,
} from './redux/app-global';
import { EAppIntent, EAppModal } from './redux/app-global/app-global.types';
import { RootState, store } from './redux/store';
import { loadLicenseDetails } from './services/global-service';
import {
  externalConfig,
  scrollInformationCenterToTop,
} from './utils/misc.utils';

localforage.config({
  name: 'cfCodeNavigator',
  storeName: 'cfCodeNavigatorStore',
  description: 'Local storage for storing code navigator data',
  version: 1.0,
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
});

function App() {
  const dispatch = useDispatch();
  const currentIntent = useSelector(
    (state: RootState) => state.appGlobal.currentIntent,
  );
  const currentIntentRef = useRef(currentIntent);
  const license = useSelector((state: RootState) => state.appGlobal.license);

  useEffect(() => {
    currentIntentRef.current = currentIntent;
    scrollInformationCenterToTop();
  }, [currentIntent]);

  useEffect(() => {
    const sessionId = sessionStorage.getItem(SESSION_HEADER);
    if (!sessionId) {
      const uuid = uuidv4();
      sessionStorage.setItem(SESSION_HEADER, uuid.toString());
    }
    setAuthHeaders();
  }, []);

  useEffect(() => {
    async function loadPublicEmailDomains() {
      if (publicEmailDomains.domains.size === 0) {
        try {
          const basePath = process.env.REACT_APP_BASE_PATH;
          const response = await axios.get(
            `${basePath}docs/public-email-domains.txt`,
          );
          const lines: string[] = response.data.split('\n');
          publicEmailDomains.domains = new Set(lines);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
          } else {
            console.error('Error fetching public email domains:', error);
          }
        }
      }
    }
    loadLicenseDetails(dispatch);
    loadPublicEmailDomains();
    if (
      process.env.REACT_APP_CF_ENV === 'onprem' &&
      (!license || !license.licenseType) &&
      sessionStorage.getItem('onprem-redirect') !== 'true'
    ) {
      sessionStorage.setItem('onprem-redirect', 'true');
      window.location.reload();
    }
  }, []);

  const handleScanCompleteEvent = () => {
    dispatch(setScanCompleted(true));

    const state = store.getState();
    if (
      state.appGlobal.currentIntent === EAppIntent.UPLOADING_ARTIFACT ||
      state.appGlobal.currentIntent === EAppIntent.REPORT
    ) {
      dispatch(setCurrentIntent(EAppIntent.REPORT));
      dispatch(setActiveModal(null));
    } else {
      dispatch(setActiveModal(EAppModal.SCANNING_COMPLETED));
    }
  };

  useEffect(() => {
    let client: Stomp.Client | null = null;
    let shouldAttemptReconnect = true;
    let reconnectTimeoutId: NodeJS.Timeout | null = null; // Variable to store timeout ID

    const connectWebSocket = () => {
      // Clear any existing reconnection attempt before starting a new one
      if (reconnectTimeoutId !== null) {
        clearTimeout(reconnectTimeoutId);
        reconnectTimeoutId = null;
      }

      try {
        const socket = new SockJS(
          `${externalConfig?.socketUrl || process.env.REACT_APP_WS_URL}`,
        );
        socket.onerror = error => {
          console.error('Socket error:', error);
          attemptReconnect();
        };
        client = Stomp.over(socket);
        client.debug = str => {
          if (process.env.NODE_ENV === 'development') {
            console.log('Socket debug:', str);
          }
        };

        client.connect(
          {},
          () => {
            console.log('WebSocket Connected');
            client?.subscribe('/topic/status', message => {
              try {
                const data = JSON.parse(message.body);
                const sessionId = sessionStorage.getItem(SESSION_HEADER);
                if (
                  data &&
                  data.sessionId &&
                  data.sessionId === sessionId &&
                  data.jobName === 'scanExecution' &&
                  data.status === 'RUNNING'
                ) {
                  console.log('Converted Socket data:', data);
                  dispatch(
                    setScanProgress(parseInt(data.completedPercentage) || 0),
                  );
                  return;
                }
                if (data && data.sessionId) {
                  if (sessionId === data.sessionId) {
                    console.log('Converted Socket data:', data);
                    if (data.isScanReportGenerated === false) {
                      // dispatch(setScanJclFailed(true));
                      dispatch(setUploadedArtifacts(undefined));
                      dispatch(setActiveModal(EAppModal.SCAN_JCL_FAILED));
                    } else if (data.jobName === 'scanExecution') {
                      handleScanCompleteEvent();
                    } else if (data.jobName === 'scanExecutionError') {
                      dispatch(setScannerErrorMessage(data.errorMessage));
                    }
                  }
                }
              } catch (error) {
                console.error('Error processing message:', error);
              }
            });
          },
          error => {
            console.error('Connection error:', error);
            attemptReconnect();
          },
        );
      } catch (error) {
        console.error('WebSocket Error: ', error);
        attemptReconnect();
      }
    };

    const attemptReconnect = () => {
      if (shouldAttemptReconnect) {
        // Clear any existing reconnection attempt
        if (reconnectTimeoutId !== null) {
          clearTimeout(reconnectTimeoutId);
        }
        reconnectTimeoutId = setTimeout(() => {
          console.log('Attempting to reconnect WebSocket...');
          connectWebSocket();
        }, 5000); // Reconnect after 5 seconds
      }
    };

    connectWebSocket();

    return () => {
      shouldAttemptReconnect = false;
      // Clear the reconnection attempt when the component is about to unmount
      if (reconnectTimeoutId !== null) {
        clearTimeout(reconnectTimeoutId);
      }
      if (client && client.connected) {
        client.disconnect(() => {
          console.log('WebSocket Disconnected');
        });
      }
    };
  }, []);

  return (
    <>
      {(process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_CF_ENV == 'onprem' ||
        window.location.hostname === 'deploy.cloudframe.com') && (
        <div className="h-[100px] w-full bg-[#0a61dc]" />
      )}
      <LayoutView>
        <main className="h-full">
          <Home />
        </main>
        <AppModals />
      </LayoutView>
    </>
  );
}

export default App;
