import React from 'react';
import { appTheme } from '../../styles/theme';

const RadialProgress = ({
  total,
  currentProgress,
}: {
  total: number;
  currentProgress: number;
}) => {
  const radius = 17; // Radius of the circle
  const stroke = 5; // Stroke width
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - (currentProgress / total) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke={appTheme.colors.tealDarkBase}
        fill={appTheme.colors.grey20}
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

export default RadialProgress;
