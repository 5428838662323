import { ProgramReport, UsageInfo } from '../model/usage.model';
import { distributeArrayElements } from './array.utils';
import { apiConfig } from './misc.utils';

function getNumberOfChunksOfLocUsage(usageData: any[]) {
  const totalDataLength = encodeURIComponent(JSON.stringify(usageData)).length;
  const charLimit = 7 * 1024;
  return Math.max(Math.ceil(totalDataLength / charLimit), 1);
}

function getCdnFetchOptions() {
  return {
    headers: {
      'Content-Security-Policy': 'upgrade-insecure-requests',
    },
  };
}

async function getHash(inputString: string) {
  let hash = 5381;
  for (let i = 0; i < inputString.length; i++) {
    const char = inputString.charCodeAt(i);
    hash = (hash * 33) ^ char;
  }
  return `${hash >>> 0}`;
}

async function extractProgData(
  data: { [s: string]: any },
  out: { [x: string]: any[] },
) {
  const values = Object.values(data);
  const type = values[0].charAt(0);
  const artifactName = await getHash(values[1]);
  const noOfLines = values[2];
  out[data?.artifactName] = [type, artifactName, noOfLines];
}

async function shrinkUsageData(data: UsageInfo[]) {
  const output = {};
  for (let i = 0; i < data.length; i++) {
    if (data[i]?.isLocUpdated) {
      await extractProgData(data[i], output);
    }
  }
  return output;
}

async function postLocChangesToCDN(
  usageObj: { [s: string]: any },
  licInfo: { licId: any; macId: any },
) {
  const usageData = Object.values(usageObj);
  const numberOfChunks = getNumberOfChunksOfLocUsage(usageData);
  const dataChunks = distributeArrayElements(usageData, numberOfChunks);
  let updatedArtifacts = 0;
  for (let i = 0; i < dataChunks.length; i++) {
    const data = dataChunks[i];
    if (data && data.length) {
      const strData = encodeURIComponent(JSON.stringify(data));
      const cdnLink = `${process.env.REACT_APP_CDN_URL}/license/sync-loc?lid=${licInfo.licId}&mc=${licInfo.macId}&data=${strData}`;
      const options = getCdnFetchOptions();
      const response = await (await fetch(cdnLink, options)).json();
      if (
        response.data &&
        response.data?.createMultipleLicenseHistories?.status === 1
      ) {
        updatedArtifacts += data.length;
      }
    }
  }
  return updatedArtifacts;
}

async function syncUsageInfo(usageJson: UsageInfo[]) {
  if (Array.isArray(usageJson) && usageJson.length) {
    const licInfo = { licId: apiConfig.LicenseId, macId: apiConfig.macAddress };
    const usageData = await shrinkUsageData(usageJson);
    await postLocChangesToCDN(usageData, licInfo);
  }
}

export function processUsageData(data?: ProgramReport): void {
  if (apiConfig.EnableCDN === 'true') {
    const output: UsageInfo[] = [];
    try {
      const listingData = data?.programReport?.programReport;
      const jclData = data?.jclReport?.jclReport;

      if (Array.isArray(listingData) && listingData.length) {
        for (let i = 0; i < listingData.length; i++) {
          const loc = listingData[i].totalNumberOfLines;
          if (loc && loc > 0) {
            output.push({
              type: 'PROGRAM',
              artifactName: listingData[i].programName,
              noOfLines: loc,
              isLocUpdated: true,
            });
          }
        }
      }
      if (Array.isArray(jclData) && jclData.length) {
        for (let i = 0; i < jclData.length; i++) {
          const loc =
            (jclData[i].noOfUtilityLines || 0) +
            (jclData[i].noOfSortLines || 0);
          if (loc && loc > 0) {
            output.push({
              type: 'JCL',
              artifactName: jclData[i].name,
              noOfLines: loc,
              isLocUpdated: true,
            });
          }
        }
      }

      if (output.length) {
        syncUsageInfo(output)
          .then(() => {})
          .catch(error => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
