import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal } from '../../redux/app-global';
import SVGAlert from '../../assets/images/svg/alert.icon';
import { EAppModal } from '../../redux/app-global/app-global.types';
import { useChatBotUtils } from '../../hooks/useChatBotUtils';
import { RootState } from '../../redux/store';

const ScanFailedModal = () => {
  const dispatch = useDispatch();
  const chatBotUtils = useChatBotUtils();
  const scannerErrorMessage = useSelector(
    (state: RootState) => state.appGlobal.scannerErrorMessage,
  );

  function handleRestart() {
    chatBotUtils.restart();
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div className="relative overflow-auto max-w-xl mx-auto max-h-[44rem] mt-[2%] flex w-full flex-col border rounded-lg bg-white p-8">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            paddingTop: '1.5rem',
          }}
        >
          <div>
            <SVGAlert />
          </div>
          <div style={{ marginTop: '2rem' }}>
            <p className="text-xl font-semibold">Processing Delayed</p>
          </div>
          <div style={{ marginTop: '.5rem' }}>
            <p style={{ fontSize: '1rem' }}>
              The processing of your artifacts is taking longer than expected.
              If you need immediate assistance, please don&apos;t hesitate to
              contact our support team.
            </p>
            {scannerErrorMessage && (
              <p>Additional details: {scannerErrorMessage}</p>
            )}
          </div>
          <div>
            <div className="flex fex-row gap-4 mt-8">
              <button
                className="rounded border-0 bg-cta py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                onClick={() => {
                  dispatch(setActiveModal(EAppModal.LEAD_INFORMATION));
                }}
              >
                Contact us
              </button>
              {/* <button onClick={handleRestart}>Start Again</button> */}
              <button
                style={{ borderWidth: 1, minWidth: '8rem', borderRadius: 4 }}
                onClick={() => {
                  dispatch(setActiveModal(null));
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanFailedModal;
