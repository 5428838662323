import { useState } from 'react';
import SVGEar from '../../assets/images/svg/ear.icon';

const FeedbackButton = ({ onClick }: { onClick: () => void }) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="relative" onClick={onClick}>
      {hover && (
        <div className="absolute w-[130px] bottom-full right-[-1.7rem] mb-2 group-hover:block px-2 py-1 bg-black text-white text-sm rounded z-10">
          Provide Feedback
        </div>
      )}
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="flex flex-col justify-center items-center hover:scale-110 transition-transform duration-300 ease-in-out relative hover:rotate-180 z-10"
      >
        <div
          className="bg-cta rounded-full p-2 shadow-lg 
        h-[50px] w-[50px] flex items-center justify-center cursor-pointer hover:bg-ctaHover transition-colors duration-300 relative z-10"
        >
          <SVGEar />
        </div>
      </div>
    </div>
  );
};

export default FeedbackButton;
