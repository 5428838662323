import { ReactNode } from 'react';
import { appTheme } from '../../styles/theme';
import { processImageSource } from '../../utils/misc.utils';

interface ICard {
  title: string;
  description: string;
  icon?: string;
  customCTA?: ReactNode;
  onClick?: () => void;
  styles?: any;
}
const Card = ({
  title,
  description,
  icon,
  customCTA,
  onClick,
  styles,
}: ICard) => {
  return (
    <div
      onClick={onClick}
      style={styles}
      className="bg-[#effaff] px-2 py-6 max-w-[15rem] rounded-md shadow-sm cursor-pointer transition-colors duration-300 ease-in-out hover:bg-background hover:shadow-sm"
    >
      <div className="flex flex-col gap-2 items-center">
        <p className=" font-semibold">{title}</p>

        {icon && (
          <div>
            <img
              src={process.env.REACT_APP_BASE_PATH + processImageSource(icon)}
              width={'85px'}
              height={'85px'}
              className="my-2"
            />
          </div>
        )}
        <p className="text-md text-grey80 min-h-[127px]">{description}</p>
        {!!onClick && (
          <div className="mt-4">
            {customCTA || (
              <svg
                fill={appTheme.colors.grey80}
                xmlns="http://www.w3.org/2000/svg"
                width={'24'}
                height={'24'}
                x="0px"
                y="0px"
                viewBox="0 0 42.6 20.1"
                //   style="enable-background:new 0 0 42.6 20.1;"
              >
                <path
                  className="st0"
                  d="M0,8.2h35.5l-5.6-5.6L32.5,0l10.1,10.1L32.5,20.1l-2.6-2.6l5.6-5.6H0V8.2z"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
