const Select = ({ options, selectedProgramId, setSelectedProgramId }: any) => {
  const isGrouped = (option: any) =>
    option.label && Array.isArray(option.options);

  return (
    <form className="max-w-md flex flex-col">
      <label
        htmlFor="countries"
        className="mb-2 text-sm font-medium text-gray-900 min-w-[8rem] ml-[2px]"
      >
        Artifact
      </label>
      <select
        id="countries"
        value={selectedProgramId}
        onChange={e => setSelectedProgramId(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-[6px]"
      >
        {options.map((option: any) =>
          isGrouped(option) ? (
            <optgroup
              label={option.label}
              key={option.label}
              className="bg-gray-200 text-gray-900 text-sm font-medium"
            >
              {option.options.map((subOption: any) => (
                <option key={subOption} value={subOption} className="pl-2 py-1">
                  {subOption}
                </option>
              ))}
            </optgroup>
          ) : (
            <option key={option} value={option} className="py-1">
              {option}
            </option>
          ),
        )}
      </select>
    </form>
  );
};

export default Select;
