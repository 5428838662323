export const VALID_FILE_TYPES = [
  'cbl',
  'cpy',
  'dcl',
  'proc',
  'pl1',
  'card',
  'prc',
  'list',
  'LIST',
  'lst',
  'LST',
  'zip',
  'ZIP',
  'jcl',
  'JCL',
  'proc',
  'PROC',
  'txt',
  'TXT',
  'xmi',
  'XMI',
];
export const SESSION_HEADER = 'X-CF-SESSIONID';
export const MAX_SCAN_TIME_FOR_5_MB = 1000 * 60; // 1 minutes
export const publicEmailDomains = {
  domains: new Set<string>([]),
};
