import { IProgressBar } from './progress-bar.types';

const ProgressBar = ({ progress }: IProgressBar) => {
  return (
    <div className="w-[20rem] bg-gray-200 rounded-full shadow-md overflow-hidden">
      <div
        className="bg-gradient-to-r from-teal-400 to-blue-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
        style={{
          width: `${progress}%`,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
          transition: 'width 0.3s ease-in-out',
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
