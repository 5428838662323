import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const AboutUsPage = () => {
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );

  return (
    <div className="container max-w-[80%] mx-auto">
      <div className="mt-4 gap-4 text-justify">
        <h1 className="text-left text-3xl font-semibold mt-2 mt-6">
          {currentContent?.heading}
        </h1>
        <h1 className="text-3xl text-grey20 mb-4">
          ----------------------------------------------
        </h1>
        <p className="mb-2">{currentContent?.description1}</p>
        <p className="mb-2">{currentContent?.description2}</p>
        <p className="mb-2">{currentContent?.description3}</p>
        <p className="mb-2">{currentContent?.description4}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-4 mb-[5%]">
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle1}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle2}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle3}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle4}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle5}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle6}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
