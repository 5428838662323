import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const CodeAccuracyPage = () => {
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );

  return (
    <div className="h-full w-full">
      <div className="text-center flex flex-col justify-center items-center mt-4 gap-4">
        <h1 className="text-3xl font-semibold">{currentContent?.heading}</h1>
        <h1 className="text-3xl text-grey20 my-4">{currentContent?.hrLine}</h1>
        <div className="mr-auto ml-[12.5%] max-w-[75%] text-justify">
          <div>
            <h2 className="text-xl font-semibold mb-4">
              {currentContent?.section1Title}
            </h2>
            <ul className="list-disc list-inside flex flex-col gap-2">
              <li>{currentContent?.section1Point1}</li>
              <li>{currentContent?.section1Point2}</li>
              <li>{currentContent?.section1Point3}</li>
            </ul>
          </div>
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">
              {currentContent?.section2Title}
            </h2>
            <ul className="list-disc list-inside flex flex-col gap-2">
              <li>{currentContent?.section2Point1}</li>
              <li>{currentContent?.section2Point2}</li>
              <li>{currentContent?.section2Point3}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeAccuracyPage;
