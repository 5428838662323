export const xmittJclArtifact = () => {
  return `
  //*<JOBCARD>
  //*------------------------------------------------------------*
  //* Instructions :                                             *
  //*                                                            *
  //* 1. Replace <Dataset> with dataset name to be bundled as XMI*
  //* 2. Replace <OutputXMIDatasetPrefix> with a suitable dataset*
  //*    name prefix                                             *
  //*    (Output XMI dataset will be automatically allocated)    *                                               
  //*------------------------------------------------------------*
  //CFXMIT PROC
  //XMITSTEP EXEC PGM=IKJEFT01
  //SYSPROC   DD DUMMY
  //SYSPRINT  DD SYSOUT=*
  //SYSTERM   DD SYSOUT=*
  //SYSUDUMP  DD DUMMY
  //SYSTSPRT  DD SYSOUT=*
  // PEND
  //*
  //XMITSTEP EXEC CFXMIT
  //SYSTSIN   DD *
     XMIT CLFR.CLFR    DA('<Dataset>') -
                    OUTDS('<OutputXMIDatasetPrefix>.JCL.XMI')
  /*`;
};

export const xmitCardArtifact = () => {
  return `
  //*<JOBCARD>
  //*------------------------------------------------------------*
  //* Instructions :                                             *
  //*                                                            *
  //* 1. Replace <Dataset> with dataset name to be bundled as XMI*
  //* 2. Replace <OutputXMIDatasetPrefix> with a suitable dataset*
  //*    name prefix                                             *
  //*    (Output XMI dataset will be automatically allocated)    *                                               
  //*------------------------------------------------------------*
  //CFXMIT PROC
  //XMITSTEP EXEC PGM=IKJEFT01
  //SYSPROC   DD DUMMY
  //SYSPRINT  DD SYSOUT=*
  //SYSTERM   DD SYSOUT=*
  //SYSUDUMP  DD DUMMY
  //SYSTSPRT  DD SYSOUT=*
  // PEND
  //*
  //XMITSTEP EXEC CFXMIT
  //SYSTSIN   DD *
     XMIT CLFR.CLFR    DA('<Dataset>') -
                    OUTDS('<OutputXMIDatasetPrefix>.CARD.XMI')
  /*
  `;
};

export const xmitProcArtifact = () => {
  return `
  //*<JOBCARD>
  //*------------------------------------------------------------*
  //* Instructions :                                             *
  //*                                                            *
  //* 1. Replace <Dataset> with dataset name to be bundled as XMI*
  //* 2. Replace <OutputXMIDatasetPrefix> with a suitable dataset*
  //*    name prefix                                             *
  //*    (Output XMI dataset will be automatically allocated)    *                                               
  //*------------------------------------------------------------*
  //CFXMIT PROC
  //XMITSTEP EXEC PGM=IKJEFT01
  //SYSPROC   DD DUMMY
  //SYSPRINT  DD SYSOUT=*
  //SYSTERM   DD SYSOUT=*
  //SYSUDUMP  DD DUMMY
  //SYSTSPRT  DD SYSOUT=*
  // PEND
  //*
  //XMITSTEP EXEC CFXMIT
  //SYSTSIN   DD *
     XMIT CLFR.CLFR    DA('<Dataset>') -
                    OUTDS('<OutputXMIDatasetPrefix>.PROC.XMI')
  /*
  `;
};

export const xmitListingArtifact = () => {
  return `
  //*<JOBCARD>
  //*------------------------------------------------------------*
  //* Instructions :                                             *
  //*                                                            *
  //* 1. Replace <Dataset> with dataset name to be bundled as XMI*
  //* 2. Replace <OutputXMIDatasetPrefix> with a suitable dataset*
  //*    name prefix                                             *
  //*    (Output XMI dataset will be automatically allocated)    *                                               
  //*------------------------------------------------------------*
  //CFXMIT PROC
  //XMITSTEP EXEC PGM=IKJEFT01
  //SYSPROC   DD DUMMY
  //SYSPRINT  DD SYSOUT=*
  //SYSTERM   DD SYSOUT=*
  //SYSUDUMP  DD DUMMY
  //SYSTSPRT  DD SYSOUT=*
  // PEND
  //*
  //XMITSTEP EXEC CFXMIT
  //SYSTSIN   DD *
    XMIT CLFR.CLFR    DA('<Dataset>') -
                   OUTDS('<OutputXMIDatasetPrefix>.LISTING.XMI')
  /*
  `;
};
