import React from 'react';

const ValidateFilesComponent = ({
  onClick,
  disabled,
}: {
    onClick: () => void;
    disabled: boolean;
}) => {
  return (
    <button
        onClick={onClick}
        disabled={disabled}>
      <label
        // type="button"
        htmlFor="file-validate"
        
        className="mt-2 md:hover:bg-offsetPlus text-textOff dark:text-textOffDark md:hover:text-textMain dark:md:hover:bg-offsetPlusDark dark:md:hover:text-textMainDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans select-none items-center relative group/button justify-center text-center items-center rounded-full cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium h-8 cursor-pointer"
      >
        <div className=" rounded-sm bg-black text-white pointer-events-none absolute z-130 opacity-0 shadow-sm transition-all delay-200 duration-200 group-hover/button:translate-y-0 group-hover/button:translate-x-0 group-hover/button:opacity-100 bottom-[120%] translate-y-1">
          <div className="py-xs px-sm rounded flex items-center gap-x-sm bg-textMain dark:bg-offsetPlusDark">
            <div className="  font-sans text-xs font-medium text-textMain dark:text-textMainDark selection:bg-superDuper selection:text-textMain px-2 py-[4px]">
              <span className="text-white">Validate your artifacts</span>
            </div>
          </div>
        </div>
        <div className="flex items-center leading-none justify-center gap-xs bg-cta text-white hover:shadow-md py-2 rounded-full px-4 mt-2">
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            className="svg-inline--fa fa-check fa-fw fa-1x"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            width={16}
            height={16}
          >
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-12.496-12.497-12.497-32.759 0-45.255s32.758-12.497 45.255 0l124.143 124.143 280.143-280.143c12.497-12.497 32.759-12.497 45.256 0s12.497 32.758 0 45.255l-325.143 325.143c-12.497 12.497-32.759 12.497-45.256 0z"
            ></path>
          </svg>
          <div className="text-align-center relative ml-[4px] text-[14px] text-white font-semibold">
            Validate
          </div>
        </div>
      </label>
    </button>
  );
};

export default ValidateFilesComponent;
