export function distributeArrayElements(arr: any[], numArrays: number): any[] {
  const result: any[] = [];
  for (let i = 0; i < numArrays; i++) {
    result.push([]);
  }
  let j = 0;
  arr.forEach(element => {
    result[j++ % numArrays].push(element);
  });
  return result;
}
