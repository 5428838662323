import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIntent } from '../../redux/app-global';
import { EAppIntent } from '../../redux/app-global/app-global.types';
import { RootState } from '../../redux/store';

const ArtifactAssessmentSetps = () => {
  const dispatch = useDispatch();
  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent,
  );
  const basePath = process.env.REACT_APP_BASE_PATH;
  return (
    <div className="p-8">
      <h1 className="text-3xl font-semibold mb-10 text-center">
        {'Artifact Assessment Steps'}
      </h1>
      <div className="flex flex-col gap-6 mb-6">
        {/* Step 1 */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md w-full">
          <h2 className="text-2xl font-semibold mb-4">
            {currentContent?.step1Heading}
          </h2>
          <hr className="my-2" />
          <div className="mr-4">
            <ul className="list-disc pl-5">
              <li>{currentContent?.step1Item1}</li>
              <li>
                <p>{currentContent?.step1Item2}</p>
                <ul className="list-decimal list-inside mt-2 mb-4 ml-4">
                  <li>
                    {currentContent?.step1Item2Item1}
                    <div className="ml-8">
                      <i>{currentContent?.step1Item2Item1Title1}</i>
                      <span>{currentContent?.step1Item2Item1Txt1}</span>
                    </div>
                  </li>
                  <li>
                    {currentContent?.step1Item2Item2}
                    <div className="ml-8">
                      <i>{currentContent?.step1Item2Item2Title1}</i>
                      <span>{currentContent?.step1Item2Item2Txt2}</span>
                    </div>
                  </li>
                </ul>
              </li>
              <li>{currentContent?.step1Item3}</li>
            </ul>
            <p className="text-sm font-semibold pt-4">
              <span>{currentContent?.step1NotePart1}</span>
              <a
                className="text-blue-500"
                href={`${basePath}docs/Final_Usage_of_Mainframe_Application_Artifacts_Bundling_JCL.pdf`}
                target="_blank"
                download
                rel="noreferrer"
              >
                {currentContent?.step1NotePart2}
              </a>
            </p>
          </div>
        </div>
        {/* Step 2 */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md w-full">
          <h2 className="text-2xl font-semibold mb-4">
            {currentContent?.step2Heading}
          </h2>
          <hr className="my-2" />
          <div className="mr-4">
            <ul className="list-disc pl-5">
              <li>{currentContent?.step2Item1}</li>
              <li>{currentContent?.step2Item2}</li>
              <li>{currentContent?.step2Item3}</li>
            </ul>
          </div>
        </div>
        {/* Step 3 */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md w-full">
          <h2 className="text-2xl font-semibold mb-4">
            {currentContent?.step3Heading}
          </h2>
          <hr className="my-2" />
          <div className="mr-4">
            <ul className="list-disc pl-5">
              <li>
                <p>{currentContent?.step3Item1}</p>
                <ol className="list-decimal list-inside mt-2 mb-4 ml-4">
                  <li>{currentContent?.step3Item1Item1}</li>
                  <li>{currentContent?.step3Item1Item2}</li>
                </ol>
              </li>
              <li>{currentContent?.step3Item2}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center my-8">
        <button
          className="bg-cta hover:shadow-md  font-semibold py-2 px-4 rounded-full mt-4 text-white"
          onClick={() => {
            dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
          }}
        >
          {currentContent?.artifactUploadBtn}
        </button>
      </div>
    </div>
  );
};

export default ArtifactAssessmentSetps;
