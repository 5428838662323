import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  EAppIntent,
  EAppModal,
  IAppGlobalState,
  ILicenseDetails,
  IUploadedArtifacts,
} from './app-global.types';

const initialState: IAppGlobalState = {
  activeModal: null, 
  currentIntent: null, 
  currentContent: {},
  customChatMessage: [],
  resetChatbot: false,
  scanCompleted: false,
  formSubmitted: false,
  emailSent: false,
  userDetails: null,
  scannerErrorMessage: '',
  uploadedArtifacts: undefined,
  scanJclFailed: false,
  isCallChainLoaded: false,
  scanProgress: 0,
  missingPrograms: [],
  missingUtilities: [],
  license: null,
};

export const appGlobalSlice = createSlice({
  name: 'app-global',
  initialState,
  reducers: {
    setActiveModal: (state, action: PayloadAction<EAppModal | null>) => {
      state.activeModal = action.payload;
    },
    setCurrentIntent(state, action: PayloadAction<EAppIntent | null>) {
      state.currentIntent = action.payload;
    },
    setCurrentContent(state, action: PayloadAction<any>) {
      state.currentContent = action.payload;
    },
    pushCustomChatMessage: (
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) => {
      const { id, message } = action.payload;
      state.customChatMessage = [...state.customChatMessage, { id, message }];
    },
    popCustomChatMessage: state => {
      state.customChatMessage = state.customChatMessage.slice(0, -1);
    },
    clearCustomChatMessages: state => {
      state.customChatMessage = [];
    },
    setResetChatbot: (state, action: PayloadAction<boolean>) => {
      state.resetChatbot = action.payload;
    },
    setScanCompleted: (state, action: PayloadAction<boolean>) => {
      state.scanCompleted = action.payload;
    },
    setFormSubmitted: (state, action: PayloadAction<boolean>) => {
      state.formSubmitted = action.payload;
    },
    setEmailSent: (state, action: PayloadAction<boolean>) => {
      state.emailSent = action.payload;
    },
    setUserDetails: (
      state,
      action: PayloadAction<IAppGlobalState['userDetails']>,
    ) => {
      state.userDetails = action.payload;
    },
    setScannerErrorMessage: (state, action: PayloadAction<string>) => {
      state.scannerErrorMessage = action.payload;
    },
    setUploadedArtifacts: (
      state,
      action: PayloadAction<IUploadedArtifacts | undefined>,
    ) => {
      state.uploadedArtifacts = action.payload;
    },
    setScanJclFailed: (state, action: PayloadAction<boolean>) => {
      state.scanJclFailed = action.payload;
    },
    setIsCallChainLoaded: (state, action: PayloadAction<boolean>) => {
      state.isCallChainLoaded = action.payload;
    },
    setScanProgress: (state, action: PayloadAction<number>) => {
      state.scanProgress = action.payload;
    },
    setMissingPrograms: (state, action: PayloadAction<string[]>) => {
      state.missingPrograms = action.payload;
    },
    setMissingUtilities: (state, action: PayloadAction<string[]>) => {
      state.missingUtilities = action.payload;
    },
    setLicense: (state, action: PayloadAction<ILicenseDetails | null>) => {
      state.license = action.payload;
    },
    setReportData: (state, action: PayloadAction<any>) => {
      state.reportData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveModal,
  setCurrentIntent,
  setCurrentContent,
  pushCustomChatMessage,
  popCustomChatMessage,
  clearCustomChatMessages,
  setResetChatbot,
  setScanCompleted,
  setFormSubmitted,
  setEmailSent,
  setUserDetails,
  setScannerErrorMessage,
  setUploadedArtifacts,
  setScanJclFailed,
  setIsCallChainLoaded,
  setScanProgress,
  setMissingPrograms,
  setMissingUtilities,
  setLicense,
  setReportData,
} = appGlobalSlice.actions;

export default appGlobalSlice.reducer;
