import ArtifactAssessmentSetps from '../../components/diagrams/cf-overview.diagram';
import { EAppIntent } from '../../redux/app-global/app-global.types';
import AboutUsPage from './about-us/about-us';
import ArtifactInfo from './artifact-info/artifact-info';
import CallChainPage from './call-chain/call-chain';
import CodeAccuracyPage from './code-accuracy/code-accuracy';
import CodeConversionProcess from './code-coversion-process/code-coversion-process';
import ExtractArtifactPage from './extract-artifact/extract-artifact';
import CloudframeServicesPage from './info-pages/cloudframe-services';
import JCLArtifactExtractPage from './jcl-artifact-extract/jcl-artifact-extract';
import JCLCCExtractPage from './jcl-cc-extract/jcl-cc-extract';
import JCLProcExtractPage from './jcl-proc-extract/jcl-proc-extract';
import ListingArtifactExtractPage from './listing-artifact-extract/listing-artifact-extract';
import MissingControlCard from './missing-controlcard/missing-controlcard';
import MissingMetaData from './missing-metadata/missing-metadata';
import MissingProcs from './missing-procs/missing-procs';
import ReportPage from './report/report';
import SampleReportPage from './report/samplereport';
import TransformationPage from './transformation-page/transformation-page';
import UploadArtifact from './upload-artifact/upload-artifact';
import DefaultUploadingArtifacts from './uploading-artifacts/uploading-artifacts';

export const intentToPageMap = (intent: EAppIntent | null) => {
  switch (intent) {
    case EAppIntent.ARTIFACT_UPLOAD:
      return <UploadArtifact />;

    case EAppIntent.UPLOADING_ARTIFACT:
      return <DefaultUploadingArtifacts />;

    case EAppIntent.ABOUT_US:
      return <AboutUsPage />;

    case EAppIntent.REPORT:
      return <ReportPage />;

    case EAppIntent.CALLCHAIN:
      return <CallChainPage />;

    case EAppIntent.SELF_SERVICE:
      return <ArtifactAssessmentSetps />;

    case EAppIntent.COBOL_TO_JAVA:
      return <CodeConversionProcess />;

    case EAppIntent.CF_SERVICES:
      return <CloudframeServicesPage />;

    case EAppIntent.SAMPLE_REPORT:
      return <SampleReportPage />;

    case EAppIntent.ARTIFACT_INFO:
      return <ArtifactInfo />;

    case EAppIntent.CODE_ACCURACY:
      return <CodeAccuracyPage />;

    case EAppIntent.EXTRACT_ARTIFACT:
      return <ExtractArtifactPage />;

    case EAppIntent.JCL_ARTIFACT_INSTRUMENTATION:
      return <JCLArtifactExtractPage />;

    case EAppIntent.JCL_CC_ARTIFACT_INSTRUMENTATION:
      return <JCLCCExtractPage />;

    case EAppIntent.JCL_PROC_ARTIFACT_INSTRUMENTATION:
      return <JCLProcExtractPage />;

    case EAppIntent.LISTING_ARTIFACT_INSTRUMENTATION:
      return <ListingArtifactExtractPage />;

    case EAppIntent.TRANSFORMATION_PAGE:
      return <TransformationPage />;

    case EAppIntent.MISSING_CONTROLCARD:
      return <MissingControlCard />;

    case EAppIntent.MISSING_METADATA:
      return <MissingMetaData />;

    case EAppIntent.MISSING_PROC:
      return <MissingProcs />;

    default:
      return <AboutUsPage />;
  }
};
