import { useEffect, useState } from 'react';
import { useCalendlyEventListener } from 'react-calendly';
import { useDispatch, useSelector } from 'react-redux';
import releaseVersions from '../../../assets/release_version.json';
import { setActiveModal } from '../../../redux/app-global';
import { RootState } from '../../../redux/store';

const AppVersion = () => {
  const [name, setName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const userDetails = useSelector(
    (state: RootState) => state.appGlobal.userDetails,
  );

  useEffect(() => {
    setTimeout(() => {
      const maxHeight = window.innerHeight * 0.95 - 75;
      const elements = document.querySelectorAll('.calendly-inline-widget');

      // Apply the calculated max-height to each element
      elements.forEach((element: any) => {
        element.style.maxHeight = `${maxHeight}px`;
      });
    }, 1000);
  }, []);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: e => {
      setSubmitted(true);
      console.log(e.data.payload);
    },
  });

  useEffect(() => {
    if (userDetails) {
      setName(userDetails.name);
      setCompanyEmail(userDetails.companyEmail);
    }
  }, [userDetails]);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setActiveModal(null));
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-gray-900 bg-opacity-50 pl-[10%]">
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: window.innerHeight * 0.95,
          overflow: 'hidden',
        }}
        className="fixed overflow-auto max-w-[40rem] flex w-full flex-col border rounded-lg bg-white p-8 py-4"
      >
        <div>
          <h2 className="title-font mb-1 text-lg font-medium text-gray-900">
            Code Navigator
          </h2>
          <button
            className="border-0 text-black float-right text-5xl leading-none font-medium outline-none focus:outline-none transition-all hover:scale-110 absolute top-2 right-6 cursor-pointer"
            onClick={handleClose}
          >
            <span className=" text-black h-6 w-6 text-4xl block outline-none focus:outline-none">
              ×
            </span>
          </button>
          <hr className="my-2" />
          <div className="card w-full">
            <h5 className="card-title mb-4 text-xl">Release Versions</h5>
            <div className="card-body border border-gray-200 rounded-lg p-4">
              <div className="card-text flex flex-col gap-4">
                <div className="w-full flex justify-start gap-4">
                  <div className="w-[40%]">App Version</div>
                  <div className="w-[60%]">{releaseVersions.codenavigator}</div>
                </div>
                {Object.entries(releaseVersions.repos).map(([key, value]) => (
                  <div key={key} className="w-full flex justify-start gap-4">
                    <div className="w-[40%]">{key.replaceAll('_', ' ')}</div>
                    <div className="w-[60%]">{value.version}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppVersion;
