import { useState } from "react";
import { Toggle, DetailsList, IColumn, Checkbox, initializeIcons, IconButton, PrimaryButton, DetailsRow } from '@fluentui/react';
import MissingUploadBtn from "../../../components/missing-upload-button/missing-upload-button";
import { UploadFile } from "../../../components/upload-list/upload-list.types";
import SVGTrash from "../../../assets/images/svg/trash.icon";
initializeIcons();
const MissingProcs = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
    const [files, setFiles] = useState<Record<string, UploadFile>>({});
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const handleCheckboxChange = (key) => {
        setSelectedRows(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };
    const handleSelectAllCheckboxChange = (event, checked) => {
        setSelectAll(checked);
        const newSelectedRows = {};
        items.forEach(item => {
            newSelectedRows[item.key] = checked;
        });
        setSelectedRows(newSelectedRows);
    };
    const [items, setItems] = useState([
        { key: 1, procName: 'Key', requiredBy: 'Artifact1', uploaded: null },
        { key: 2, procName: 'Key', requiredBy: 'Artifact2', uploaded: null }
    ]);
    const handleFileUpload = (event, itemKey) => {
        const file = event.target.files[0];
        if (file) {
            setItems(prevItems => prevItems.map(item =>
                item.key === itemKey ? { ...item, uploaded: file.name } : item
            ));
        }
    };
    const handleZipUpload = (event, itemKey) => {
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (fileExtension === 'zip' || fileExtension === 'xmi') {
                setUploadedFiles(prevFiles => [...prevFiles, file.name]);
            } else {
                alert('Only .zip and .xmi files are allowed.');
            }
        }
        const fileSet = event.target.files;
        if (!fileSet) {
            event.preventDefault();
            event.target.value = '';
            return;
        }
        const newFiles = { ...files };
        if (fileSet && fileSet.length) {
            for (let i = 0; i < fileSet.length; i++) {
                const file = fileSet[i];
                newFiles[file.name] = getUploadFileInfo(file);
                setFiles(newFiles)
            }
        }
    };
    const handleCancelUpload = (itemKey) => {
        setItems(prevItems => prevItems.map(item =>
            item.key === itemKey ? { ...item, uploaded: null } : item
        ));
    };
    const handleDeleteFile = (fileName: string) => {
        const newFiles = { ...files };
        delete newFiles[fileName];
        setFiles(newFiles);
    };
    const changeDisplay = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setToggleValue(checked);
    }
    const columns: IColumn[] = [
        {
            key: 'ignoreAll', name: '', fieldName: 'ignoreAll', minWidth: 100, maxWidth: 200, onRender: (item) =>
                <div className="flex items-center justify-center mr-16">
                    <Checkbox
                        checked={selectedRows[item.key] || false}
                        onChange={() => handleCheckboxChange(item.key)}
                        styles={{
                            checkbox: { borderRadius: '50%', padding: '5px' },
                        }}
                    />
                </div>, onRenderHeader: () => (
                    <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllCheckboxChange}
                        className="mt-2"
                        title="Ignore All"
                        label="Ignore All"
                        styles={{ checkbox: { borderRadius: "50%", padding: "5px", marginRight: "15px" }, label: { fontWeight: "500" } }}
                    />
                )
        },
        { key: 'procName', name: 'Proc Name', fieldName: 'procName', minWidth: 100, maxWidth: 200 },
        { key: 'requiredBy', name: 'Required By JCL/Proc', fieldName: 'requiredBy', minWidth: 100, maxWidth: 200 },
        ...(!toggleValue ? [{
            key: 'uploadProc',
            name: 'Upload Proc',
            fieldName: 'uploadProc',
            minWidth: 150,
            maxWidth: 200,
            onRender: (item) => (
                selectedRows[item.key] ? (
                    item.uploaded ?
                        <div className="flex items-center gap-2 text-gray-400">
                            {item.uploaded}
                        </div> :
                        <MissingUploadBtn handleFileUpload={handleFileUpload} item={item} buttonStyle={{ cursor: 'not-allowed', backgroundColor: "#f0f0f0" }} />
                ) : (
                    item.uploaded ?
                        <div className="flex items-center gap-2">
                            {item.uploaded}
                            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => handleCancelUpload(item.key)} style={{ color: 'red', marginTop: '3px' }} />
                        </div> :
                        <MissingUploadBtn handleFileUpload={handleFileUpload} item={item} />
                )
            )
        }] : [])
    ];
    const getUploadFileInfo = (file: any): UploadFile => {
        const fileInfo: UploadFile = {
            name: file.name,
            size: formatFileSize(file.size),
            type: getFileType(file),
            originalFile: file,
            uploadProgress: 0,
            error: '',
        };
        return fileInfo;
    };
    const formatFileSize = (size: number) => {
        return size < 1024
            ? `${size} B`
            : size < 1048576
                ? `${(size / 1024).toFixed(2)} KB`
                : size < 1073741824
                    ? `${(size / 1048576).toFixed(2)} MB`
                    : `${(size / 1073741824).toFixed(2)} GB`;
    };
    const getFileType = (file: any) => {
        return file.name.includes('.')
            ? file.name.split('.').pop().toUpperCase()
            : '';
    };
    return (
        <div className={`container mx-auto p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-[85%]`}>
            <h1 className="text-3xl font-semibold mb-4">Missing Artifacts Workspace</h1>
            <hr />
            <div className="flex flex-col gap-4">
                <div className="text-left mt-4 gap-4 text-l font-semibold">Missing Proc Information</div>
                <div>Provide dataset for the missing Procs below or upload the missing Procs.</div>
                <div className="flex items-center gap-4">
                    <span className="text-sm">Upload Procs</span>
                    <Toggle className="mt-2" onChange={changeDisplay} />
                    <span className="text-sm">Extract Procs from Mainframe</span>
                </div>
                {!toggleValue && (
                    <>
                        <div className="font-semibold mt-4">Upload Proc as File</div>
                        <hr />
                    </>
                )}

                <div className="mb-2 border rounded-lg p-4 px-2 shadow">
                    <DetailsList
                        items={items}
                        columns={columns}
                        setKey="set"
                        layoutMode={0}
                        selectionMode={0}
                        checkButtonAriaLabel="select row"
                        className="mb-4"
                        onRenderRow={(props: any) => {
                            const customStyles = {
                                cell: {
                                    color: selectedRows[props.item.key] ? 'gray' : 'inherit'
                                }
                            };
                            return <DetailsRow {...props} styles={customStyles} />;
                        }}
                    />
                </div>
                {!toggleValue ? (
                    <div>
                        <h2 className="flex items-center justify-center font-bold ">OR</h2>
                        <div className="font-semibold mb-2">Upload Procs as XMI/ZIP</div>
                        <hr />
                        {Object.values(files).length > 0 && (
                            <div className="overflow-auto mt-4">
                                {Object.values(files).map((file: UploadFile, index: number) => (
                                    <div
                                        key={index}
                                        className={`mb-2 border rounded-lg p-4 px-2 shadow`}
                                        style={{
                                            borderWidth: 1,
                                        }}
                                    >
                                        <div className="grid grid-cols-12 items-center">
                                            <div className="ml-2 h-10 w-10 flex items-center justify-center bg-gray-200 rounded-lg text-gray-700">
                                                {file.type}
                                            </div>
                                            <div className="col-span-5 flex flex-col items-start">
                                                <div className="flex-grow truncate text-[14px] font-semibold">
                                                    {file.name}
                                                </div>
                                                <div className="w-24 text-[14px]">{file.size}</div>
                                            </div>
                                            <div className="col-span-5"></div>
                                            <div className="flex justify-end mr-4">
                                                <button className="ml-2 text-gray-700 hover:text-gray-900">
                                                    <span className="text-red-500">
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => handleDeleteFile(file.name)}
                                                        >
                                                            <SVGTrash />
                                                        </div>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>)}
                        <div className="flex flex-col gap-4 mt-4 mb-2 border rounded-lg p-4 px-2 shadow">
                            Upload the missing Procs bundled inside a zip/xmi.
                            <MissingUploadBtn handleFileUpload={handleZipUpload} accept=".zip,.xmi" />
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3>Enter the comma seperated Dataset names</h3>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                            placeholder="Enter dataset names..."
                        />
                    </div>
                )}
            </div>
            {(items.some(item => item.uploaded !== null) || Object.values(files).length > 0 || toggleValue) && (
                <PrimaryButton text={toggleValue ? "Generate Extraction JCL" : "Save and Continue"} className="mt-8 rounded-md" styles={{ root: { backgroundColor: "#1363DF" } }} />
            )}
        </div>
    )
}
export default MissingProcs;