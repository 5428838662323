export class SharedService {
  static handleOption = null;
  static genericModalMessage = '';
  static reportAPICount = 0; //Used for keeping count of number of times reportAPI is triggered
  static documentationHtmlContent = '';
  static documentationProgramName = '';
  static genericAlertModal = {
    title: '',
    description: '',
  };
  static missingPrograms = new Set<string>();
  static missingUtilities = new Set<string>();
}
