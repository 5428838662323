interface ILoaderComponent {
  title: string;
}

const LoaderComponent = ({ title }: ILoaderComponent) => {
  return (
    <div className="my-4">
      <p className="pb-2">{title}</p>
      <div className="loader">
        <div className="loader-inner" />
      </div>
    </div>
  );
};

export default LoaderComponent;
